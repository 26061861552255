.textData {
    margin-top: 10%;
    text-align: left;
}

#root {
    background-color: var(--background);
    color: var(--text-primary);
    
    /* max-width: 100%; */
  }